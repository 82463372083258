/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * TFA API
 * API for TFA-v2
 * OpenAPI spec version: 2.0.0 (api.admin.v1)
 */
import type {
  AdminV1PaymentsSubscriptionPlansListParams,
  PaginatedSubscriptionPlanList,
  SubscriptionPlan,
  SubscriptionPlanCreateRequest
} from '.././model'
import adminV1PaymentsSubscriptionPlansListMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriptionPlansCreateMutator from '../../shared/services/axios-client';
import adminV1PaymentsSubscriptionPlansRetrieveMutator from '../../shared/services/axios-client';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  export const adminV1PaymentsSubscriptionPlansList = (
    params?: AdminV1PaymentsSubscriptionPlansListParams,
 options?: SecondParameter<typeof adminV1PaymentsSubscriptionPlansListMutator>,) => {
      return adminV1PaymentsSubscriptionPlansListMutator<PaginatedSubscriptionPlanList>(
      {url: `/api/admin/v1/payments/subscription-plans/`, method: 'GET',
        params
    },
      options);
    }
  export const adminV1PaymentsSubscriptionPlansCreate = (
    subscriptionPlanCreateRequest: SubscriptionPlanCreateRequest,
 options?: SecondParameter<typeof adminV1PaymentsSubscriptionPlansCreateMutator>,) => {
      return adminV1PaymentsSubscriptionPlansCreateMutator<SubscriptionPlan>(
      {url: `/api/admin/v1/payments/subscription-plans/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionPlanCreateRequest
    },
      options);
    }
  export const adminV1PaymentsSubscriptionPlansRetrieve = (
    id: string,
 options?: SecondParameter<typeof adminV1PaymentsSubscriptionPlansRetrieveMutator>,) => {
      return adminV1PaymentsSubscriptionPlansRetrieveMutator<SubscriptionPlan>(
      {url: `/api/admin/v1/payments/subscription-plans/${id}/`, method: 'GET'
    },
      options);
    }
  export type AdminV1PaymentsSubscriptionPlansListResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriptionPlansList>>>
export type AdminV1PaymentsSubscriptionPlansCreateResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriptionPlansCreate>>>
export type AdminV1PaymentsSubscriptionPlansRetrieveResult = NonNullable<Awaited<ReturnType<typeof adminV1PaymentsSubscriptionPlansRetrieve>>>
