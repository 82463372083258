<template>
  <div class="flex mb-4 justify-end items-center">
    <div class="flex items-center gap-4">
      <Button label="Create Subscription" class="px-4" />
    </div>
  </div>
  <AppCard class="mt-6 animate-fadein animate-duration-1000">
    <DataTable
      :value="activeSubscriptions"
      striped-rows
      row-hover
      selection-mode="single"
    >
      <Column header="Title">
        <template #body="slotProps">
          <div class="text-base">
            <span class="font-bold">{{ slotProps.data.title }}</span
            ><br />
            <span :class="getSubtextColor(slotProps.data)">{{
              getSubtextForTitle(slotProps.data)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Subscribers">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              slotProps.data.subscriber_membership_count === null
                ? "-"
                : slotProps.data.subscriber_membership_count
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Amount">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getFormattedCurrency(slotProps.data.amount)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Recurring Rev.">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              slotProps.data.recurring_revenue === null
                ? "-"
                : getFormattedCurrency(slotProps.data.recurring_revenue)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Frequency">
        <template #body="slotProps">
          <div class="text-base">
            <span class="">{{
              getFrequencyDisplayValue(slotProps.data.payment_frequency)
            }}</span>
          </div>
        </template>
      </Column>
      <Column header="Enrollment">
        <template #body="slotProps">
          <div class="text-base">
            <span
              v-if="slotProps.data.enrollment_status"
              :class="getStatusColor(slotProps.data.enrollment_status)"
              >{{ capitalize(slotProps.data.enrollment_status) }}</span
            >
          </div>
        </template>
      </Column>
      <Column header="Share">
        <template #body="slotProps">
          <div class="text-base">
            <a
              v-if="
                slotProps.data.enrollment_status === EnrollmentStatusEnum.open
              "
              href="#"
              class="text-blue-600 hover:text-blue-800 inline-flex items-center"
              @click.prevent="copyUrl(slotProps.data.share_url)"
            >
              <i class="ml-2 pi pi-copy" />
            </a>
          </div>
        </template>
      </Column>
    </DataTable>
  </AppCard>
</template>

<script setup lang="ts">
import AppCard from "@/shared/components/AppCard.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import { ref, onMounted, computed } from "vue";
import {
  EnrollmentStatusEnum,
  PaymentFrequencyEnum,
  type SubscriptionPlan,
} from "@/api/model";
import { useToast } from "primevue/usetoast";
import { getFormattedCurrency } from "@/modules/payments/helpers";
import { capitalize } from "lodash";
import { getFormattedDate } from "../helpers";
import { adminV1PaymentsSubscriptionPlansList } from "@/api/admin-payments-subscription-plans/admin-payments-subscription-plans";
import { fetchSubscriptions } from "../api";

const toast = useToast();

const activeSubscriptions = computed(() => {
  return subscriptions.value.filter(
    (subscription) => subscription.archived_at === null
  );
});

const subscriptions = ref<SubscriptionPlan[]>([]);

const copyUrl = async (url: string) => {
  try {
    await navigator.clipboard.writeText(url);
    toast.add({
      severity: "success",
      summary: "Success",
      detail: "URL copied to clipboard",
      life: 5000,
    });
  } catch (err) {
    console.error("Failed to copy URL: ", err);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to copy URL",
      life: 5000,
    });
  }
};

const getStatusColor = (status: string) => {
  switch (status) {
    case "open":
      return "text-green-600";
    case "closed":
      return "text-red-600";
    case "archived":
      return "text-gray-600";
    default:
      return "";
  }
};

const getSubtextColor = (subscription: SubscriptionPlan) => {
  if (subscription.published_at === null) {
    return "text-blue-600";
  } else {
    return "text-gray-600";
  }
};

const getSubtextForTitle = (subscription: SubscriptionPlan) => {
  if (subscription.archived_at !== null) {
    return "Archived: " + getFormattedDate(subscription.archived_at);
  } else if (subscription.published_at === null) {
    return "Draft";
  } else {
    return "Created: " + getFormattedDate(subscription.created_at);
  }
};

const frequencyDisplayMap: Record<PaymentFrequencyEnum, string> = {
  [PaymentFrequencyEnum.weekly]: "Weekly",
  [PaymentFrequencyEnum.every_two_weeks]: "Every 2 Weeks",
  [PaymentFrequencyEnum.monthly]: "Monthly",
  [PaymentFrequencyEnum.quarterly]: "Quarterly",
  [PaymentFrequencyEnum.annually]: "Annually",
  [PaymentFrequencyEnum.every_six_months]: "Every 6 Months",
};

const getFrequencyDisplayValue = (paymentFrequency: PaymentFrequencyEnum) => {
  return frequencyDisplayMap[paymentFrequency];
};

onMounted(async () => {
  subscriptions.value = await fetchSubscriptions();
});
</script>
