export const getFormattedDate = (date: string) => {
  const parsedDate = new Date(date);

  if (isNaN(parsedDate.getTime())) {
    console.error("Invalid date");
    return "";
  }

  const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
  const day = parsedDate.getDate().toString().padStart(2, "0");
  const year = parsedDate.getFullYear().toString().slice(-2);

  return `${month}/${day}/${year}`;
};
