<template>
  <BaseLayout title="Video Submissions">
    <div class="flex flex-col space-y-4">
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <Select
          v-model="statusSelection"
          :options="StatusOptions.filter((status) => status.id !== 'Resolved')"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Status"
          class="w-full"
          @change="filterResults"
        />

        <Select
          v-model="categoryTypeSelection"
          :options="[
            { name: 'Hitting', id: 'Hitting' },
            { name: 'Catching', id: 'Catching' },
            { name: 'Pitching', id: 'Pitching' },
            { name: 'Infield', id: 'Infield' },
            { name: 'Outfield', id: 'Outfield' },
            { name: 'Base Running', id: 'Base Running' },
          ]"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Category"
          class="w-full"
          @change="filterResults"
        />

        <Select
          v-model="coachSelection"
          :options="coachesListOptions"
          option-label="name"
          option-value="id"
          show-clear
          placeholder="Coach"
          class="w-full"
          @change="filterResults"
        />
      </div>

      <div class="flex justify-between items-center">
        <AppButton
          icon="pi pi-refresh"
          :loading="isLoading"
          class="w-10 h-10"
          @click="onRefreshClick"
        />
        <CreateChatDialog
          class="w-auto"
          @chat-created="handleVideoSubmissionCreated"
        />
      </div>
    </div>
    <AppCard
      v-if="noVideoSubmissions"
      class="mt-6 animate-fadein animate-duration-1000"
    >
      <DataTable
        :loading="isLoading"
        :value="allChats"
        striped-rows
        row-hover
        selection-mode="single"
        @row-click="
          (e) => {
            addMixpanelEvent(EVENT_NAMES.Navigation.NavAdminVideoSubmission);
            router.push({
              name: ROUTE_NAME.CHAT_SINGLE,
              params: { id: e.data.id },
            });
          }
        "
      >
        <Column header="Video">
          <template #body="slotProps">
            <div class="flex gap-6 items-center">
              <div>
                <img
                  v-if="getAssetThumbnail(slotProps.data.asset, 100)"
                  :src="getAssetThumbnail(slotProps.data.asset, 100)"
                  class="rounded-lg"
                  alt="image"
                />
              </div>
              <div class="flex flex-col gap-1">
                <div class="font-bold text-base">
                  {{ slotProps.data.sender.name }}
                </div>
                <div class="text-surface-500 dark:text-surface-300 text-sm">
                  {{
                    format(
                      new Date(slotProps.data.created_at),
                      "MM/dd/yy h:mm aa"
                    )
                  }}
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Status">
          <template #body="slotProps">
            <div
              class="flex flex-col gap-1"
              :class="
                getReassignmentStatus(slotProps.data).exceedLimit &&
                getReassignmentStatus(slotProps.data).shouldShow
                  ? 'text-red-600	'
                  : ''
              "
            >
              <div>
                {{ getStatusTitle(slotProps.data).label }}
              </div>
              <div
                v-if="getReassignmentStatus(slotProps.data).shouldShow"
                class="text-sm"
              >
                {{ getReassignmentStatus(slotProps.data).label }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="category" header="Category" />
        <Column field="id" header="Reference ID" sortable />
      </DataTable>

      <Paginator
        :always-show="true"
        :rows="resultsPerPage"
        :total-records="totalRecords"
        :first="first"
        template="
        FirstPageLink
        PrevPageLink
        CurrentPageReport
        NextPageLink
        LastPageLink
        RowsPerPageDropdown"
        current-page-report-template="Showing {first} to {last} of {totalRecords}"
        @page="onPageChange"
      />
    </AppCard>

    <AppCard v-if="!noVideoSubmissions" class="mt-6">
      No videos found.
    </AppCard>
  </BaseLayout>
</template>

<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import router from "@/router";

import {
  AdminV1ChatsListCategory,
  type AdminChat,
  type AdminCoach,
  NewStatusEnum,
} from "../../../api/model";

import BaseLayout from "@/shared/components/BaseLayout.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import CreateChatDialog from "./CreateChatDialog.vue";
import AppCard from "@/shared/components/AppCard.vue";
import Select from "primevue/select";

import { ROUTE_NAME } from "@/shared/constants/routes";
import {
  getStatusTitle,
  StatusOptions,
} from "@/modules/chats/helpers/videoSubmissionsUtils";

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format,
} from "date-fns";

import { useCurrentUser } from "@/composables/useCurrentUser";
import { useOrganizationMembers } from "@/composables/useOrganizationMembers";
import { useToast } from "primevue/usetoast";
import { useRoute } from "vue-router";

import { getAllChats } from "@/modules/chats/api";
import { getAssetThumbnail } from "@/shared/utils/helpers";
import { debounce } from "lodash";
import { getCoaches } from "../../organizations/api";
import { addMixpanelEvent, EVENT_NAMES } from "../../../shared/utils/analytics";

const { defaultOrganizationId, isStaff } = useCurrentUser();
const { fetchOrgMembers } = useOrganizationMembers();

const route = useRoute();
const toast = useToast();

const paginationNum = ref(parseInt((route?.query?.page || 1).toString()));

const first = computed(
  () => paginationNum.value * resultsPerPage - resultsPerPage
);

let resultsPerPage = 10;

const onPageChange = async (e: any) => {
  paginationNum.value = e.page + 1;

  await getChatsPaginated();
};
function getReassignmentStatus(chat: AdminChat) {
  if (!chat.created_at) return { hours: 0, exceedLimit: false, label: "" };

  const now = new Date();
  const createdAtDateTime = new Date(chat.created_at);
  const hours = differenceInHours(now, createdAtDateTime);
  const minutes = differenceInMinutes(now, createdAtDateTime);
  const days = differenceInDays(now, createdAtDateTime);
  const hoursMinusDays = hours - days * 24;
  const minutesMinusHours = minutes - hours * 60;
  const dayPlural = days === 1 ? "" : "s";
  const hourMinusDaysPlural = hoursMinusDays === 1 ? "" : "s";
  const hourPlural = hours === 1 ? "" : "s";
  const minutePlural = minutes === 1 ? "" : "s";
  const minuteMinusHoursPlural = minutesMinusHours === 1 ? "" : "s";

  let humanReadableTime;
  if (hours > 23) {
    humanReadableTime = `${days} day${dayPlural}, ${hoursMinusDays} hour${hourMinusDaysPlural} ago`;
  } else if (hours === 0) {
    humanReadableTime = `${minutes} minute${minutePlural} ago`;
  } else {
    humanReadableTime = `${hours} hour${hourPlural}, ${minutesMinusHours} minute${minuteMinusHoursPlural} ago`;
  }

  return {
    hours,
    exceedLimit: hours > 36,
    shouldShow: chat.status === "Pending",
    label: humanReadableTime,
  };
}

const allChats = ref<AdminChat[]>([]);
const isLoading = ref(false);
const totalRecords = ref(0);
const noVideoSubmissions = ref(true);

const organizationSelection = ref<string | undefined>(undefined);
const categoryTypeSelection = ref<AdminV1ChatsListCategory | undefined>(
  undefined
);
const coachSelection = ref<number | undefined>(undefined);
const statusSelection = ref<keyof typeof NewStatusEnum | undefined>(undefined);
let coachesListOptions = ref<{ id: number; name: string }[]>([]);

const getChatsPaginated = async () => {
  isLoading.value = true;
  router.push({
    query: {
      page: paginationNum.value ?? undefined,
    },
  });

  const status__in: string[] = statusSelection.value
    ? [statusSelection.value]
    : [];

  const chatResponse = await getAllChats({
    limit: resultsPerPage ?? undefined,
    offset: (paginationNum.value - 1) * resultsPerPage,
    organization:
      organizationSelection.value && !isStaff
        ? parseInt(organizationSelection.value)
        : undefined,
    category: categoryTypeSelection.value ?? undefined,
    status__in: status__in,
    recipient: coachSelection.value ?? undefined,
  });

  if ("error" in chatResponse) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: chatResponse.error,
      life: 10000,
    });
  } else {
    const results = chatResponse.results || [];

    allChats.value = results;
    totalRecords.value = (chatResponse || {}).count || 0;

    noVideoSubmissions.value = totalRecords.value > 0;
  }

  isLoading.value = false;
};

const onRefreshClick = debounce(async () => {
  addMixpanelEvent(EVENT_NAMES.VideoSubmissions.SubmissionsRefreshed);
  filterResults();
}, 500);

const filterResults = debounce(async () => {
  paginationNum.value = 1;

  await getChatsPaginated();

  noVideoSubmissions.value = allChats.value.length > 0;
}, 500);

const getCoachesSelection = async () => {
  const coaches = await getCoaches(
    defaultOrganizationId.value as number,
    10000,
    0
  );
  if ("error" in coaches) {
    return [];
  }
  const coachesList = coaches.results || [];
  coachesListOptions.value = coachesList.map((coach: AdminCoach) => {
    return {
      name: coach.profile.name ?? "",
      id: coach.profile.id ?? -1,
    };
  });
  return coaches;
};

const handleVideoSubmissionCreated = async () => {
  statusSelection.value = undefined;
  categoryTypeSelection.value = undefined;
  coachSelection.value = undefined;

  await getChatsPaginated();
};

onMounted(async () => {
  await getChatsPaginated();
  await getCoachesSelection();
  await fetchOrgMembers();
});
</script>
