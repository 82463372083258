import { adminV1PaymentsSubscriptionPlansList } from "@/api/admin-payments-subscription-plans/admin-payments-subscription-plans";
import type {
  PaginatedSubscriptionPlanList,
  SubscriptionPlan,
} from "@/api/model";
import type { AdminV1PaymentsSubscriptionPlansListParams } from "@/api/model/adminV1PaymentsSubscriptionPlansListParams";

export const fetchSubscriptions = async (): Promise<SubscriptionPlan[]> => {
  const params: AdminV1PaymentsSubscriptionPlansListParams = {
    limit: 1000,
    offset: 0,
  };
  const response: PaginatedSubscriptionPlanList =
    await adminV1PaymentsSubscriptionPlansList(params);
  if (!response.results) {
    console.error("Failed to fetch subscriptions");
    return [];
  }
  return response.results;
};
