export const ROUTE_NAME = {
  HOME: "home",
  LOGIN: "login",
  LOGIN_PASSWORD: "login-password",
  LOGIN_MAGIC_LINK: "auth-magic-link",
  LOGOUT: "logout",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",

  FEED: "feed",
  FEED_LIST: "feed-list",
  FEED_SINGLE: "feed-single",

  TRAINING_COLLECTIONS: "training-collection",
  TRAINING_COLLECTION_LIST: "training-collection-list",
  TRAINING_COLLECTION_UPDATE: "training-collection-single",

  DRILL_UPDATE: "drill-update",

  CALENDARS: "calendars",
  WORKOUTS: "workouts",
  WORKOUT_LIST: "workout-list",
  WORKOUT_UPDATE: "workout-single",
  WORKOUT_CREATE: "workout-create",

  EXERCISES: "exercises",
  EXERCISE_LIST: "exercise-list",
  EXERCISE_UPDATE: "exercise-single",
  EXERCISE_CREATE: "exercise-create",

  USERS: "users",
  USER_LIST: "user-list",
  USER_UPDATE: "user-single",
  USER_CREATE: "user-create",

  CHATS: "chats",
  CHAT_LIST: "chat-list",
  CHAT_SINGLE: "chat-single",
  CHAT_MESSAGE: "chat-message",

  ORGANIZATIONS: "organizations",
  ORGANIZATION_LIST: "organization-list",
  ORGANIZATION_UPDATE: "organization-single",
  ORGANIZATION_CREATE: "organization-create",

  TEAM_LIST: "team-list",
  TEAM_UPDATE: "team-single",
  TEAM_CREATE: "team-create",

  GLOBAL_SETTINGS: "settings",

  SENTRY: "sentry",

  PROFILE_UPDATE: "profile-single",

  PAYMENT_INVOICES: "payment-invoices",
  CREATE_INVOICE: "create-invoice",
  VIEW_INVOICE: "view-invoice",
  EDIT_INVOICE: "edit-invoice",
  PAYMENT_SUBSCRIPTIONS: "payment-subscriptions",
  ORGANIZATION_PAYMENT_SETTINGS: "organization-payment-settings",

  STATS_CATEGORIES: "list-stat-categories",
  CREATE_STAT_CATEGORY: "create-stat-category",
  UPDATE_STAT_CATEGORY: "update-stat-category",
} as const;

export const LOGIN_PATH = "/login";
export const LOGIN_EMAIL_PATH = "/login-email/:email?";
export const LOGOUT_PATH = "/logout";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";
export const LOGIN_MAGIC_LINK_PATH = "/login/magic-link/:token";
